// Morse modules
const PrimaryNavClass = require("./modules/primary_nav.es6.js");

// Primary Navigation
const primaryNav = document.getElementById("primary-nav");

if(primaryNav) {
  new PrimaryNavClass({
    element: primaryNav
  });
}
// End Primary Navigation