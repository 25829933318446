const _ = require("lodash");
const ViewportDetect = require("viewport-detection-es6");
const viewport = new ViewportDetect();

class PrimaryNavClass {
  constructor(config = {}, init = true) {
    this.config = _.defaults(config,
      {id: "primary-nav"},
      {list_id: "primary-nav-list"},
      {toggle_mobile_id: "primary-nav-toggle-mobile"},
      {toggle_id: "primary-nav-toggle"},
      {dropdown_class: "dropdown-parent"}
    );

    if(init) {
      this._initViewport();
      this._init();
    }
  }

  render() {
    this._addToggleListener();
    this._getBodyClass();
    this._getDropdownParents();
    this._hideNav();
    this._setToggleAriaHidden(this._deviceCheck() ? false : true);
  }

  _addDropdownHoverListener(dropdown) {
    dropdown.addEventListener("mouseover", this._setDropdownAriaHiddenDesktop.bind(this), false);
    dropdown.addEventListener("mouseout", this._setDropdownAriaHiddenDesktop.bind(this), false);
  }

  _addDropdownClickListener(dropdownParentMobile) {
    dropdownParentMobile.addEventListener("click", this._dropdownParentClick.bind(this), false);
  }

  _addToggleListener() {
    if(!_.isNull(this.toggle)) {
      this.toggle.addEventListener("click", this._toggleClick.bind(this), false);
    }

    if(!_.isNull(this.toggleMobile)) {
      this.toggleMobile.addEventListener("click", this._toggleClick.bind(this), false);
    }
  }

  _deviceCheck() {
    return (this.device === "mobile" || this.device === "tablet");
  }

  _dropdownParentClick(e) {
    if(this._deviceCheck()) {
      this._hideDropdown(e.target);
      this._setDropdownAriaHiddenMobile(e.target);
    }

    e.preventDefault();
  }

  _getBodyClass() {
    this.body = document.body;
    this.bodyClass = this.body.getAttribute("class");
  }

  _getDropdownParents() {
    let listItems = this.list.getElementsByTagName("li");

    _.forEach(listItems, function(li) {
      if(li.className === this.config.dropdown_class) {
        this.dropdownParents.push(li);
      }
    }.bind(this));

    _.forEach(this.dropdownParents, this._addDropdownHoverListener.bind(this));

    this._getDropdownParentsMobile();
  }

  _getDropdownParentsMobile() {
    _.forEach(this.dropdownParents, function(dropdownParent) {
      this.dropdownParentsMobile.push(this._skipTextNodes(dropdownParent, "firstChild"));
    }.bind(this));

    _.forEach(this.dropdownParentsMobile, this._addDropdownClickListener.bind(this));
  }

  _hideDropdown(dropdownParentMobile) {
    let dropdown = this._skipTextNodes(dropdownParentMobile, "nextSibling");

    if(dropdown.offsetParent === null) {
      dropdown.style.display = "block";
      dropdownParentMobile.className += "open";

    } else {
      dropdown.style.display = "none";
      dropdownParentMobile.className = "";
    }
  }

  _hideNav(force) {
    if(_.isBoolean(force)) {
      this.hideMenu = force;
    } else {
      this.hideMenu = (this._deviceCheck()) ? !this.hideMenu : false;
    }

    this._setBodyClass(this.hideMenu);
    this._setToggleAriaExpanded(this.hideMenu);
  }

  _init() {
    this.dropdownParents = [];
    this.dropdownParentsMobile = [];
    this.list = document.getElementById(this.config.list_id);
    this.toggle = document.getElementById(this.config.toggle_id);
    this.toggleMobile = document.getElementById(this.config.toggle_mobile_id);
    this.render();
  }

  _initViewport() {
    this.device = viewport.getDevice();
    this.size = viewport.windowSize();
    viewport.trackSize(this._trackSize.bind(this));
  }

  _resetDropdownParentsStates() {
    _.forEach(this.dropdownParentsMobile, function(dropdownParent) {
      dropdownParent.className = "";
    });

    _.forEach(this.dropdownParents, function(dropdownParent) {
      let ul = dropdownParent.getElementsByTagName("ul")[0];
      ul.style.display = "none";
      ul.setAttribute("aria-hidden", "true");
    });
  }

  _setBodyClass(hidden) {
    this.body.className = hidden ? this.bodyClass : this.bodyClass + " nav-open";
  }

  _setDropdownAriaHiddenDesktop(e) {
    if(!this._deviceCheck()) {
      let ul;
      let hoveredParent = e.target.parentNode;

      if(hoveredParent.className === this.config.dropdown_class) {
        ul = hoveredParent.getElementsByTagName("ul")[0];
      } else {
        ul = hoveredParent.parentNode;
      }

      ul.setAttribute("aria-hidden", ul.offsetParent === null ? "true" : "false");
    }
  }

  _setDropdownAriaHiddenMobile(dropdownParent) {
    let ul = this._skipTextNodes(dropdownParent, "nextSibling");

    ul.setAttribute("aria-hidden", ul.offsetParent === null ? "true" : "false");
  }

  _setToggleAriaExpanded (hidden) {
    this.toggle.setAttribute("aria-expanded", hidden ? "false" : "true");
  }

  _setToggleAriaHidden (hidden) {
    this.toggle.setAttribute("aria-hidden", hidden ? "true" : "false");
  }

  _skipTextNodes(el, method) {
    let element = el[method];

    while(element !== null && element.nodeType !== 1) {
      element = element.nextSibling;
    }

    return element;
  }

  _toggleClick(e) {
    this._hideNav();
    e.preventDefault();
  }

  _trackSize(device, size) {
    this._resetDropdownParentsStates();

    if(this.device !== device) {
      this.device = device;
    }
    if(this._deviceCheck()) {
      this._hideNav(true);
      this._setToggleAriaHidden(false);
    } else {
      this._hideNav(false);
      this._setToggleAriaHidden(true);
    }
    this.size = size;
  }
}

module.exports = PrimaryNavClass;
